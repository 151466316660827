import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Titolo per pagina Progetti",
  "description": "Descrizione per pagina Progetti",
  "h1": "Lorem ipsum dolor sit amet, consectetur adipiscing",
  "h2": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ContainerFixed = makeShortcode("ContainerFixed");
const SectionFullWidth = makeShortcode("SectionFullWidth");
const Progetti = makeShortcode("Progetti");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ContainerFixed mdxType="ContainerFixed">
      <SectionFullWidth className="pt-5 pb-2 pt-lg-5 pb-lg-3" mdxType="SectionFullWidth">
        <h3>{`Progetti`}</h3>
        <p>{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec risus dui, eleifend ac facilisis in, pretium in sapien. Morbi a sem eget urna laoreet placerat nec a orci. Vivamus quis lectus sit amet turpis commodo viverra in eget urna. Aenean at feugiat leo.`}</p>
        <Progetti itemsPerPage={4} mdxType="Progetti" />
      </SectionFullWidth>
    </ContainerFixed>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      